import * as React from "react"
import { useContext } from "react"
import { LoginContext } from "components"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import GiftListUpdater from "../../social-supermarket/pages/gifting/gift-list/GiftListUpdater"

const UpdateOptions = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Update Order Options"} description={""} />
      <GiftListUpdater />
    </Layout>
  ) : (
    <Layout />
  )
}

export default UpdateOptions
