import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { GiftListContext } from "../../../context/GiftListProvider"
import { GiftOrderContext } from "../../../context/GiftOrderProvider"
import { isFullySelected } from "../../../util/productUtil"
import { Icon } from "semantic-ui-react"
import {
  Buttons,
  DEFAULT_ATTRIBUTE_STATE,
  LoginContext,
  PrimaryButton,
  ProductModal,
  ProductQuantityInput,
} from "components"
import { getProduct } from "../../../api/productApi"
import { AttributeStateType, getFormattedPriceRange, ProductType, VariantType } from "model"

const ButtonsContainer = styled(Buttons)`
  align-items: center;
  justify-content: flex-end;
`

const Price = styled.div`
  margin-right: 15px;
`
const QuantityInput = styled(ProductQuantityInput)`
  width: 75px;
  height: 40px;
`

interface Props {
  productId: number
  onClose
  show
  mode?: "order" | "gift-list" | ""
  priceIncludesShipping: boolean
}

const ProductGiftModal: FC<Props> = ({ productId, onClose, show, mode, priceIncludesShipping }) => {
  const { user } = useContext(LoginContext)
  const { choices, addChoices } = useContext(GiftListContext)
  const {
    basket: { items },
    addBasketItems,
  } = useContext(GiftOrderContext)

  const [product, setProduct] = useState<ProductType>(null)

  const [quantity, setQuantity] = useState<number>(1)

  const [attributeState, setAttributeState] = useState<AttributeStateType>({
    ...DEFAULT_ATTRIBUTE_STATE,
  })

  useEffect(() => {
    if (productId) {
      fetchProduct()
    }
  }, [productId])

  useEffect(() => {
    if (!show) {
      setProduct(null)
    }
  }, [show])

  useEffect(() => {
    if (product) {
      setQuantity(product.moq)
      setAttributeState({ ...DEFAULT_ATTRIBUTE_STATE, variants: product.variants })
    } else {
      setAttributeState({ ...DEFAULT_ATTRIBUTE_STATE })
    }
  }, [product, show])

  const handleAddToGiftPage = () => {
    if (product.variants) {
      const selections = inStockVariants.map(variant => ({ product, variant }))
      addChoices([...selections])
    } else {
      addChoices([{ product, variant: null }])
    }
    onClose()
  }

  const handleAddToBasket = () => {
    if (product.variants) {
      const selections = inStockVariants.map(variant => ({
        product,
        variant,
        quantity,
      }))
      addBasketItems([...selections])
    } else {
      addBasketItems([{ product, variant: null, quantity }])
    }
    onClose()
  }

  const getAddToGiftPageButtonLabel = () => {
    if (isFullySelected(product, choices, inStockVariants)) {
      return <Icon name={"check"} />
    } else if (product?.variants && attributeState.variants) {
      return `Add ${inStockVariants.length} Type${
        inStockVariants.length > 1 ? "s" : ""
      } To Gift Page`
    } else {
      return "Add To Gift Page"
    }
  }

  const inStockVariants = getInStockSelectedVariants(attributeState.variants) || []
  const showBoth = !mode && choices.length === 0 && items.length === 0

  const isFullySelectedAlready =
    show && product && isFullySelected(product, choices, inStockVariants)

  const hasItemsToAdd = product?.variants ? inStockVariants.length > 0 : true
  const hasOneItem = product?.variants ? inStockVariants.length === 1 : true

  const fetchProduct = async () => {
    try {
      const product = await getProduct(productId, user.ringfenced)
      setProduct(product)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ProductModal
      product={product}
      onClose={onClose}
      show={show}
      attributeState={attributeState}
      setAttributeState={setAttributeState}
    >
      {product && (
        <ButtonsContainer>
          <Price>
            {getFormattedPriceRange(
              product,
              attributeState.variants,
              quantity,
              null,
              priceIncludesShipping
            )}
          </Price>

          {(choices.length > 0 || showBoth || mode === "gift-list") && (
            <PrimaryButton
              disabled={isFullySelectedAlready || !hasItemsToAdd}
              onClick={handleAddToGiftPage}
              slim
            >
              {getAddToGiftPageButtonLabel()}
            </PrimaryButton>
          )}

          {(items.length > 0 || showBoth || mode === "order") && (
            <>
              <QuantityInput product={product} quantity={quantity} onChange={v => setQuantity(v)} />
              <PrimaryButton disabled={!hasOneItem} onClick={handleAddToBasket} slim>
                Add To Basket
              </PrimaryButton>
            </>
          )}
        </ButtonsContainer>
      )}
    </ProductModal>
  )
}

const getInStockSelectedVariants = (variants: VariantType[]) => {
  return variants?.filter(variant => {
    return variant.stockStatus === "IN_STOCK"
  })
}

export default ProductGiftModal
